import { DataNode } from 'rc-tree/lib/interface';

import {
  AccessLevelEnum,
  AccessLevelType,
  MapCoordinatesType,
  SpaceStateIncidentsType,
} from '@portals/types';

import { IncidentPriorityEnum } from '../incidents';

export enum SpaceIncidentViewEnum {
  SpacesWithIncidents = 'spaces_with_incidents',
  SpacesWithoutIncidents = 'incident_free_spaces',
}

export interface SpaceIncidentRateFormType {
  title: string;
  spaceIncidentView: SpaceIncidentViewEnum;
  incidentPriority: IncidentPriorityEnum[];
}

export type SpaceStateDevicesStatusByModelType = Record<
  string,
  {
    online_devices: number;
    claimed_devices: number;
  }
>;

export interface SpaceStateType {
  devices: number;
  local_devices: number;

  incidents: SpaceStateIncidentsType;
  local_incidents: SpaceStateIncidentsType;

  devices_status_by_device_model: SpaceStateDevicesStatusByModelType;
  local_devices_status_by_device_model: SpaceStateDevicesStatusByModelType;

  online_devices: number;
  local_online_devices: number;

  snoozed: boolean;
  maintenance: boolean;
}

export interface SpaceConfigType {
  location: {
    location: MapCoordinatesType;
    region_name: string;
    description: string;
  };
  priority_factor: number;
  inherit_location: boolean;
  inherit_priority_factor: boolean;
  spaces_incident_rate?: {
    title?: string;
    space_incident_rate_priorities?: IncidentPriorityEnum[];
    space_incident_rate_view?: SpaceIncidentViewEnum;
  };
}

export type NonNormalizedSpaceType = SpaceType & {
  path: string;
};

export interface IncidentRoutingConfigType {
  // Is on/off
  tree: {
    incidents: {
      email?: boolean;
      gchat?: boolean;
      slack?: boolean;
      vacant?: boolean;
      msteams?: boolean;
      occupied?: boolean;
      cisco_teams?: boolean;
      service_now?: boolean;
      fresh_service?: boolean;
    };
  };

  // Inheritance
  inherit_tree: false;
  'inherit_incidents.email': true;
  'inherit_incidents.gchat': true;
  'inherit_incidents.slack': true;
  'inherit_occupancy.vacant': true;
  'inherit_incidents.msteams': true;
  'inherit_occupancy.occupied': true;
  'inherit_incidents.cisco_teams': true;
  'inherit_incidents.service_now': true;
  'inherit_incidents.fresh_service': true;

  // Config
  'incidents.email'?: {
    email: string;
  };
  'incidents.gchat'?: Record<string, any>;
  'incidents.slack'?: Record<string, any>;
  'incidents.vacant'?: Record<string, any>;
  'incidents.msteams'?: Record<string, any>;
  'incidents.occupied'?: Record<string, any>;
  'incidents.cisco_teams'?: Record<string, any>;
  'incidents.service_now'?: Record<string, any>;
  'incidents.fresh_service'?: Record<string, any>;
}

export interface SpaceType {
  id: number;
  space_type: 'room' | 'root';
  name: string;
  custom_id: string | null;
  config: SpaceConfigType | null;
  note: string | null;
  path: Array<number>;
  position?: number;
  created_at: string;
  parent_id: number | null;
  organization_id: string;
  tree_path_name: string;
  state: SpaceStateType | null;
  access_level: AccessLevelEnum;
  incident_routing_config: IncidentRoutingConfigType | null;
}

export interface SpaceTreeNodeType extends DataNode {
  id: SpaceType['id'];
  name: SpaceType['name'];
  path: SpaceType['path'];
  position?: SpaceType['position'];
  parent_id: SpaceType['parent_id'];
  access_level: SpaceType['access_level'];
  incidents: SpaceStateIncidentsType | undefined;
  local_incidents: SpaceStateIncidentsType | undefined;
  device_count: number | undefined;
  online_devices: number | undefined;
  local_device_count: number | undefined;
  local_online_devices: number | undefined;
  title: string;
  lowerCaseTitle: string;
  children: Array<SpaceTreeNodeType>;
}

export interface GroupAccessItem {
  id: string;
  name: string;
  is_external: boolean;
  support_partner_display_name: string | null;
  access_level: AccessLevelType;
}

export interface UserAccessItem {
  id: string;
  name: string;
  email: string;
  is_external: boolean;
  support_partner_display_name: string | null;
  access_level: AccessLevelType;
}

export interface SpaceDetailsType extends SpaceType {
  access: {
    users: Array<UserAccessItem>;
    groups: Array<GroupAccessItem>;
  };
}

export interface CreateSpaceFileMutationParams {
  spaceId: number;
  fileData: {
    name: string;
    desc?: string;
    file_type?: string;
    url: string;
  };
}

export interface UpdateSpaceMutationParams {
  spaceId: number;
  updatedSpace: Partial<SpaceType> & {
    maintenance?: boolean;
  };
}

export interface CreateSpaceMutationParams {
  parentSpaceId: number;
  newSpace: { name: string };
}

export interface MoveSpaceMutationParams {
  spaceId: number;
  targetSpaceId: number;
  position?: number;
}

export type StatsHistoryType = {
  timestamp: string;
  data: {
    devices: number;
    snoozed: boolean;
    local_devices: number;
    online_devices: number;
    local_online_devices: number;
    incidents: SpaceStateIncidentsType;
    local_incidents: SpaceStateIncidentsType;
    devices_status_by_device_model: SpaceStateDevicesStatusByModelType;
    local_devices_status_by_device_model: SpaceStateDevicesStatusByModelType;
  };
};
